import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../apiService'; // Ensure this is the correct path to your API service
import logo1 from '../assets/logo1.svg';
import storefrontPromptImage from '../assets/neededStoreFront.png';
import productsPromptImage from '../assets/storeInsideImageNeeded.jpg';
import selfiePromptImage from '../assets/uploadSelfiNeeded.png';

function UploadImages() {
    const [currentStep, setCurrentStep] = useState(1);
    const [location, setLocation] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedData, setUploadedData] = useState({
        storeFrontPicUrl: '',
        productPicUrl: '', // Single product image
        selfiePicUrl: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (userData && userData.selfiePicUrl) {
            navigate('/reviewImage');
        }
    }, [navigate]);
    
    const handleFileChange = (fileType) => async (event) => {
        const files = event.target.files;
        setIsUploading(true);
        if (files.length > 0) {
            const file = files[0]; // Assuming we're only handling one file for storefront and selfie
            try {
                const response = await apiService.uploadImage(file);
                // console.log(`${fileType}PicUrl `+ response);
                if (response) {
                    setUploadedData(prev => ({
                        ...prev,
                        [`${fileType}PicUrl`]: response // Update the appropriate key based on fileType
                    }));
                    
                    sessionStorage.setItem(`${fileType}PicUrl`, response);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                alert('Failed to upload image. Please try again.');
            }
        }
        setIsUploading(false);
    };

    const handleNext = () => {
        if (currentStep < 3) setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            }, (error) => {
                console.error('Error getting location:', error);
                setLocation({
                    lat: 0,
                    lng: 0
                });
                alert('Error getting location');
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = { ...uploadedData, location };
        try {
            const response = await apiService.updateShop(data);
            console.log(response);
            navigate('/reviewImage');
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('Failed to submit data. Please try again.');
        }
    };

    // Adjusted to access properties from uploadedData
    const isFormValid = () => {
        console.log(uploadedData)
        return uploadedData.storeFrontPicUrl && 
               uploadedData.productPicUrl && 
               uploadedData.selfiePicUrl && 
               location;
    };
    
  
    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <Typography variant="h6" sx={{ mt: 2 }}>Upload Store Front</Typography>
                        <Typography sx={{ mb: 2 }}>Please upload a clear picture of your store front.</Typography>
                        <img src={storefrontPromptImage} alt="Storefront Prompt" style={{ width: '100%', height: 'auto' }} />
                    </>
                );
            case 2:
                return (
                    <>
                        <Typography variant="h6" sx={{ mt: 2 }}>Upload Product Image</Typography>
                        <Typography sx={{ mb: 2 }}>Upload a clear image of your products (inside shop).</Typography>
                        <img src={productsPromptImage} alt="Products Prompt" style={{ width: '100%', height: 'auto' }} />
                    </>
                );
            case 3:
                return (
                    <>
                        <Typography variant="h6" sx={{ mt: 2 }}>Upload Selfie Outside Store</Typography>
                        <Typography sx={{ mb: 2 }}>Take a selfie with the store front in the background.</Typography>
                        <img src={selfiePromptImage} alt="Selfie Prompt" style={{ width: '100%', height: 'auto' }} />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Container>
            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" onSubmit={handleSubmit} padding={5} boxShadow={2} bgcolor="white" sx={{ borderRadius: '8px', width: '100%', maxWidth: '500px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <img src={logo1} alt="Logo" style={{ maxWidth: '150px', marginBottom: '20px' }} />
                            {renderCurrentStep()}
                        </div>
                        <Button variant="contained" component="label" fullWidth sx={{ mt: 1 }} disabled={isUploading}>
                            Upload Image
                            <input type="file" hidden onChange={handleFileChange(currentStep === 1 ? 'storeFront' : currentStep === 2 ? 'product' : 'selfie')} />
                        </Button>
                        {uploadedData[`${currentStep === 1 ? 'storeFront' : currentStep === 2 ? 'product' : 'selfie'}PicUrl`] && (
                            <Typography sx={{ mt: 1 }}>Image uploaded: {uploadedData[`${currentStep === 1 ? 'storeFront' : currentStep === 2 ? 'product' : 'selfie'}PicUrl`]}</Typography>
                        )}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                            {currentStep > 1 && <Button onClick={handlePrev}>Previous</Button>}
                            {currentStep < 3 && <Button onClick={handleNext} disabled={isUploading}>Next</Button>}
                            {currentStep === 3 && <Button onClick={handleLocation}>Capture Location</Button>}
                        </Box>
                        {currentStep === 3 && location && <Typography sx={{ mt: 2 }}>Location: {location.lat.toFixed(2)}, {location.lng.toFixed(2)}</Typography>}
                        {currentStep === 3 && <Button type="submit" fullWidth sx={{ mt: 2 }} disabled={!isFormValid()}>Submit</Button>}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UploadImages;