import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Container, Box, Grid } from '@mui/material';
import logo1 from '../assets/logo1.svg';
import '../styles/login.css';
import { useNavigate } from 'react-router-dom';
import apiService from '../apiService'; // Adjust the path as necessary

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData) {
        navigate('/uploadImages');
    }
}, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const response = await apiService.getShopsByEmail(email, password);
        if (response.length > 0) {
          
          const userData = response[0];
          sessionStorage.setItem('userData', JSON.stringify(userData));
            setLoginStatus('Login Successful');
            navigate('/uploadImages');
        } else {
            setLoginStatus('Login Failed');
            alert('Invalid email or password');
        }
    } catch (error) {
        console.error('Error logging in:', error);
        setLoginStatus('Login Failed');
        alert('Login failed, please try again later.');
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <Container component="main">
      <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item>
          <Box
            boxShadow={3}
            bgcolor="background.paper"
            p={4}
            borderRadius={4}
            maxWidth={400}
            mx="auto"
          >
            <div style={{ textAlign: 'center' }}>
              <img src={logo1} alt="Logo" className="login-logo" />
              <Typography component="h1" variant="h5">Sign in</Typography>
              <form onSubmit={handleLogin} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ borderRadius: 28, color: 'white', backgroundColor: "#121858", margin: "20px 0" }}
                >
                  Sign In
                </Button>
              </form>
              <Button
                fullWidth
                variant="contained"
                sx={{ borderRadius: 28, color: 'white', backgroundColor: "#121858" }}
                onClick={handleRegister}
              >
                Click here to register
              </Button>
              {loginStatus && <div>{loginStatus}</div>}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
