import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Registration from './components/Registration';
import Wallet from './components/Wallet';
import UploadImages from './components/UploadImages';
import ReviewImage from './components/ReviewImage';
import Dashboard from './components/Dashboard';


function App() {
  return (
    <Router>
      <Routes basename="/">
      <Route path="/" element={<Login />} exact />
      <Route path="/glimpass-shop" element={<Login />} exact/>
        <Route path="/login" element={<Login />} exact/>
        <Route path="/register" element={<Registration />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/uploadImages" element={<UploadImages />} />
        <Route path="/reviewImage" element={<ReviewImage />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
    
    
  );
}

export default App;
