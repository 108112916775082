const baseUrl = 'https://application.glimpass.com/shop';

const apiService = {
    createShop: async (data) => {
        const response = await fetch(`${baseUrl}/create-shop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    },

    updateShop: async (data) => {
        const storedUserData = sessionStorage.getItem('userData');
const userData = JSON.parse(storedUserData);
        const payload = {
            key : userData._id,
            shopData : data
        };
        const response = await fetch(`${baseUrl}/update-shop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        return response.json();
    },
    
    getShopsByEmail: async (email, password) => {
        const response = await fetch(`${baseUrl}/get-shops-by-mail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ mail: email, password })
        });
        return response.json();
    },

    uploadImage: async (imageFile) => {
        const formData = new FormData();
        formData.append('file', imageFile);
        formData.append('folderName', 'shops'); // Add folder name to the form data
        const response = await fetch(`https://application.glimpass.com/graph/upload`, {
            method: 'POST',
            body: formData,
        });

        return response.json(); // Assuming the server responds with JSON
    },
    // Define other API methods as needed
};

export default apiService;
