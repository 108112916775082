import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid } from '@mui/material';
import logo1 from '../assets/logo1.svg';
import '../styles/login.css';
import { useNavigate } from 'react-router-dom';
import apiService from '../apiService';

function Registration() {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData) {
        navigate('/uploadImages');
    }
}, [navigate]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNo: '',
    shopName: '',
    password: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    mobileNo: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear errors when the user starts correcting them
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const validateForm = () => {
    const { name, email, mobileNo, shopName, password } = formData;
    let newErrors = { email: '', mobileNo: '' };
    let isValid = true;

    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        newErrors.email = 'Please enter a valid email address.';
        isValid = false;
    }
    if (!mobileNo || !/^\d{10}$/.test(mobileNo)) {
        newErrors.mobileNo = 'Mobile number must be 10 digits.';
        isValid = false;
    }

    setErrors(newErrors);
    return isValid && name && shopName && password; // Check other fields are not empty
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        alert('Please correct the errors before submitting.');
        return;
    }

    setIsSubmitting(true);

    try {
        const response = await apiService.createShop(formData);
        alert("You are registered successfully.\n Please log in!");
        navigate('/login');
        console.log(response);
    } catch (error) {
        console.error('Error creating shop:', error);
        alert('Error creating shop. Please try again.');
        setIsSubmitting(false);
    }
  };

  return (
    <Container component="main">
      <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item>
          <Box boxShadow={3} bgcolor="background.paper" p={4} borderRadius={4} maxWidth={400} mx="auto">
            <div style={{ textAlign: 'center' }}>
              <img src={logo1} alt="Logo" className="login-logo" />
              <Typography component="h1" variant="h5">Registration</Typography>
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="ownerName"
                  label="Owner Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  error={!!errors.email}
                  helperText={errors.email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  error={!!errors.mobileNo}
                  helperText={errors.mobileNo}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mobileNo"
                  label="Mobile No"
                  name="mobileNo"
                  autoComplete="tel"
                  value={formData.mobileNo}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="shopName"
                  label="Shop Name"
                  name="shopName"
                  autoComplete="off"
                  value={formData.shopName}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ borderRadius: 28, color: 'white', backgroundColor: "#121858", margin: "20px 0" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Registering...' : 'Register'}
                </Button>
              </form>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Registration;
