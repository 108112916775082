import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Container, Grid } from '@mui/material';
import logo from '../assets/logo1.svg'; // Make sure the path is correct
import apiService from '../apiService'; // Ensure this is the correct path to your API service

function Wallet() {
    const [balance, setBalance] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const updatePendingBalance = async (amount) => {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (userData) {
            const updatedUserData = {
                ...userData,
                pendingBal: amount
            };

            try {
                // Assuming updateShop is your method to update user data on the backend
                const response = await apiService.updateShop(updatedUserData);
                if (response) { // Check if the response is ok
                    sessionStorage.setItem('userData', JSON.stringify(updatedUserData)); // Update sessionStorage
                } else {
                    throw new Error('Failed to update the backend.');
                }
            } catch (error) {
                console.error('Error updating pending balance:', error);
                alert('Failed to update pending balance. Please try again.');
            }
        }
    };

    const generateUPILink = (amount) => {
        const baseLink = "upi://pay?pa=devashishjaiswal84@okaxis&pn=Devashish Jaiswal&cu=INR";
        return `${baseLink}&am=${amount}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const numericBalance = Number(balance);
        if (numericBalance < 130) {
            alert("Minimum amount is 130");
            return;
        }

        setIsProcessing(true); // Show processing message

        await updatePendingBalance(numericBalance); // Update pending balance

        const upiLink = generateUPILink(numericBalance);
        setTimeout(() => {
            window.location.href = upiLink; // Navigate to UPI link for payment
            setIsProcessing(false); // Hide processing message after operation
        }, 2000);
    };

    return (
        <Container>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <img src={logo} alt="Logo" style={{ textAlign: 'center', maxWidth: '150px', marginBottom: '20px' }} />
                <Grid item xs={12}>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        maxWidth="xs"
                        padding={5}
                        boxShadow={2}
                        bgcolor="white"
                        sx={{ borderRadius: '8px' }}
                    >
                        <Typography
                            component="h1"
                            variant="h5"
                            textAlign="center"
                            fontFamily="'Poppins', sans-serif"
                        >
                            Top-Up Your Advertising Wallet
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="balance"
                            label="Balance Amount"
                            name="balance"
                            type="number"
                            autoFocus
                            value={balance}
                            onChange={(e) => setBalance(e.target.value)}
                            sx={{
                                '& label.Mui-focused': {
                                    color: '#121858',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#121858',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#121858',
                                    },
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ borderRadius: 28, color: 'white', backgroundColor: "#121858", margin: "20px 0" }}
                            disabled={isProcessing}
                        >
                            {isProcessing ? 'Processing...' : 'Pay'}
                        </Button>
                        {isProcessing && (
                            <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
                                Processing, money loading is in process...
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Wallet;
