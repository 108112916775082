import React, { useState } from 'react';
import { Box, Grid, Typography, Paper, Menu, MenuItem, IconButton } from '@mui/material';
import Logo from '../assets/logo1.svg';
import WalletIcon from '../assets/wallet.svg';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem('userData'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWalletMenuClick = (pageUrl) => {
        navigate(pageUrl);
        handleClose();
    };

    return (
        <Box p={2}>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <img src={Logo} alt="Logo" style={{ maxWidth: '100px' }} />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClick}>
                            <img src={WalletIcon} alt="Wallet" style={{ width: '70px' }} />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={() => handleWalletMenuClick('/wallet')}>Pending Balance: {JSON.parse(sessionStorage.getItem('userData')).pendingBal || 0}</MenuItem>
                            <MenuItem onClick={() => handleWalletMenuClick('/wallet')}>Available Balance: {JSON.parse(sessionStorage.getItem('userData')).availableBal || 0}</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <Typography variant="h4" gutterBottom align="center">{userData?.shopName || 'Data Processing...'}</Typography>
                <Typography variant="subtitle1" gutterBottom align="center">Owned by {userData?.name || 'Data Processing...'}</Typography>
                <Typography gutterBottom align="center">{userData?.email || 'Data Processing...'}</Typography>
                <Typography gutterBottom align="center" sx={{ color: userData?.status === 'Active' ? 'green' : 'red' }}>
                    Profile Status: {userData?.status || 'Pending'}
                </Typography>
                <Typography gutterBottom align="center">
                    Category: {userData?.category || <span style={{ color: 'red' }}>Data Processing...</span>}
                </Typography>
                <Typography gutterBottom align="center">
                    Tags: {userData?.tags?.join(', ') || <span style={{ color: 'red' }}>Data Processing...</span>}
                </Typography>
            </Paper>
        </Box>
    );
}

export default Dashboard;
