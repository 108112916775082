import React, {useEffect} from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function ReviewImage() {
    
  const navigate = useNavigate();
  
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData && userData.status) {
        navigate('/dashboard');
    }
}, [navigate]);

  const handleClick = () =>{
    navigate('/dashboard');
  }
    return (
        
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, textAlign: 'center' }}>
                <Typography variant="h6" sx={{ color: 'green', mb: 2 }}>Submission Successful</Typography>
                {/* Using inline SVG for the green check circle */}
                <svg height="100" width="100">
                    <circle cx="50" cy="50" r="40" stroke="green" strokeWidth="4" fill="green" />
                    <path stroke="#fff" strokeWidth="4" d="M30 50 l20 20 l40 -40" />
                </svg>
                <Typography sx={{ mt: 2 }}>Images has been submitted successfully. Review process is going on</Typography>
                <Button onClick={handleClick}>OK</Button>
            </Box>
    );
}
export default ReviewImage;